@media (max-width: 1400px) {
	.container {
		width: 1200px;
		padding: 0 15px;
	}
	.home-banner {
		width: 1200px;
	}
	.home-section-container {
		width: 1200px;
	}
    .banner-overlay {
        width:1200px;
    }
    .banner-caption {
        left:3%;
    }
}
@media (max-width: 1200px) {
	.container,
    .banner-overlay,
	.home-banner,
	.home-section-container {
		width: 1024px;
	}
    .banner-caption {
        width:58%;
        h1 {
            font-size:27px;
        }
    }
    #page-inner {
        #welcome {
            padding:10px 50px;
            .project-content {
                .project-tag {
                    a {
                        right:25px;
                    }
                }
            }
        }
    }
}
@media (max-width: 1054px) {
	.container,
    .banner-overlay,
	.home-banner,
	.home-section-container {
		width: 960px;
	}
}
@media (max-width: 990px) {
	.container,
    .banner-overlay,
	.home-banner,
	.home-section-container {
		width: 768px;
	}
    .banner-caption {
        width:400px;
        font-size:23px;
    }
	#navigation > ul > li > a {
	    padding: 20px 0;
	}
	#page-contact .contact-form {
	    width: 100%;
	}
	.case-studies-entry {
	    width: 100%;
		padding: 0 0 30px;
		margin: 0 0 30px;
		border-bottom: 1px solid #ccc;
	}

    /* Stack Services */
    #page-services {
        .service {
            flex-direction: column;
            .service-img {
                width:100%;
                order:2 !important;
            }
            .service-text {
                width:100%;
                order:1 !important;
            }
        }
    }
    .image-list li {
        width:45%;
    }
    #page-inner {
        #welcome {
            h2 {
                width:70%;
                font-size:18px;
                &:after {
                    right:110px;
                    height:31px;
                }
            }
            .project-content {
                .project-tag {
                    h1 {
                        font-size:18px;
                        padding:0 25px;
                    }
                    a {
                        right:15px;
                    }
                }
                    .project-desc {
                        width:35%;
                    }
            }

        }
    }
}
@media (max-width: 798px) {
	.container,
    .banner-overlay,
	.home-banner,
	.home-section-container {
		width: 600px;
	}
    .banner-caption {
        width:50%;
        top:30%;
    }
	.top-contact, .main-menu {
	    display: none;
	}
	.top-header {
		padding: 10px 0;
	}
	.banner-overlay {
	    height: 500px;
	}
	.banner-caption {
	    width: 70%;
	    left: 5%;
	    padding: 8px;
	    border-right: 0;
	    border-left: 0;
        h1 {
	       font-size: 20px;
	    }
	}
    #page-inner {
        #welcome {
            .project-content {
                column-count:1;
                .project-desc {
                    position: static;
                    border-bottom:2px solid white;
                }
                .project-tag {
                    h1 {
                        width:60%;
                    }
                    .projectBtn {
                        padding:18px 20px;
                    }
                }
            }
        }
    }
    #page-services {
        #welcome {
            padding:10px 25px;
        }
    }
	#welcome {
		padding: 20px 40px 0 40px;
        h2 {
		  margin: 20px 0;
        }
	}
	.case-studies-grid {
	    width: 100%;
	    margin: 0 0 20px 0;
	}
	#case-studies {
		height: auto;
	}
	.contact-form {
	    width: 100%;
	}
	.contact-address {
	    padding: 0;
	    width: 100%;
	}
	#mobile-menu {
	    display: block;
	}
	#logo {
	    padding: 0 0 0 10px;
	}
	.mobile-btn {
	    right: 10px;
	}
    /* PROJECT DETAIL PAGE: STACK PICTURES For MOBILE */
    #page-inner {
        #welcome {
            padding:10px 20px;
            h2 {
                width:100%;
                text-align: center;
                &:after {
                    display:none;
                }
            }
            .project-content {
                .project-desc {
                    width:100%;
                    &:after {
                        display:none;
                    }
                }
                .project-gallery {
                    flex-flow:column;
                    li {
                        max-height:none;
                    }
                    .list1, .list2, .list3 {
                        width:100%;
                        flex-basis:100%;
                        img {
                            max-height:400px;
                        }
                    }
                }
            }
            
        }
    }
    .image-list li {
        width:100%;
    }
    
}
@media (max-width: 630px) {
	.container,
    .banner-overlay,
	.home-banner,
	.home-section-container {
		width: 480px;
	}
	.banner-caption {
		width:60%;
	}
	#logo {
	    width: 160px;
	    overflow: hidden;
        img {
            width:100%;
        }
	}
    #page-services {
        .service {
            .service-img {
                img {
                    height:300px;
                }
            }
        }
    }
}
@media (max-width: 510px) {
    #page-services,
    #page-projects,
    #page-contact,
    #page-\{all\},
    #page-inner,
    #page-login {
        #welcome {
            padding:10px 10px;
        }
    }
    #email {
        a {
            padding:20px;
        }
    }
    .banner-caption {
        top:25%;
    }
    .projectBtn {
        padding:20px;
    }
	.container,
    .banner-overlay,
	.home-banner,
	.home-section-container {
		width: 100%;
	}
    .banner-caption {
        .projectBtn {
            bottom:-80px;
        }
    }
	.welcome-content p:first-child {
	    font-size: 16px;
	}
    
    #page-contact {
        #welcome {
            padding:50px 20px;
        }
    }
    #page-inner {
        #welcome {
            .project-content {
                .project-tag {
                    h1 {
                        width:60%;
                        font-size:16px;
                    }
                }
            }
        }
    }
}
