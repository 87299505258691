/*
Theme Name: MMD
*/
* {
	margin: 0;
	padding: 0;
	outline: 0;
	box-sizing: border-box;
}
body {
    color: #575757;
	font-family: 'Source Sans Pro', sans-serif;
}
a {
	color: #690b0f;
	text-decoration: none;
}
p {
    line-height: 26px;
}
i {
    color:rgb(15,78,107);
}
li {
	list-style: none;
}
img {
	vertical-align: middle;
}
input[type="text"],
input[type="email"],
input[type="tel"] {
    font-size: 16px;
    height: 54px;
    padding: 0 0 0 15px;
    width: 100%;
    margin:10px 0;
}
textarea {
    font-size: 16px;
    padding: 15px;
    resize: none;
    width: 100%;
    height: 200px;
}
input[type="submit"] {
    height: 54px;
    border: 0;
    background: #690b0f;
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    width: 200px;
    cursor: pointer;
}
blockquote {
    font-style: italic;
    font-weight: 700;
}
.container {
	width: 1110px;
	margin: auto;
}
.placeholder {
    min-height:100px;
    width:100%;
    background-color: rgb(240,240,240);
}


/* ===============================================
 * HEADER
 * ============================================= */
#header {
    position: fixed;
    width: 100%;
    z-index: 9;
}
.top-header {
    background: #fff;
    width: 100%;
    display: inline-block;
    padding: 30px 4%;
    margin: 0 0 -5px;
    transition: 0.5s;
}
#logo {
    float: left;
}
.top-contact {
    float: right;
    position: relative;
    text-align:right;
    font-size:20px;
    p {
        color:rgb(211,88,37);
    }
    a {
        text-decoration:none;
        color:rgb(46,110,140);
    }
}
.main-menu {
    background-color: rgba(0,0,0,0.35);
    width: 100%;
    display: inline-block;
    text-align: center;
    transition:background-color 0.5s ease-in-out;
}
#navigation {
    position: relative;
    overflow: hidden;
    border-bottom: 3px solid rgb(210,89,37);
}
#navigation > ul > li {
	display: inline;
}
#navigation > ul > li > a {
	color: #fff;
    font-size: 17px;
    text-transform: capitalize;
    float: left;
    padding: 24px 0;
    position: relative;
    transition: 0.5s;
    width: 14%;
    font-size: 20px;
    font-weight: 300;
}
#navigation > ul > li > a:hover {
    color: rgb(210,89,37);
}
#navigation > ul > li.current-menu-item > a:after {
    content: "";
    width: 20px;
    height: 20px;
    background: rgb(210,89,37);
    position: absolute;
    left: 50%;
    bottom: -10px;
    margin: 0 0 0 -10px;
    transform: rotate(45deg);
}
#navigation > ul > li > a:hover:after {
    content: "";
    width: 20px;
    height: 20px;
    background: rgb(210,89,37);
    position: absolute;
    left: 50%;
    bottom: -10px;
    margin: 0 0 0 -10px;
    transform: rotate(45deg);
}
.home-banner-container {
    background: linear-gradient(rgb(7,77,107) 28%, rgb(10,85,119) 28%);
}
.home-banner {
    height: 100%;
    width: 1400px;
    position: relative;
    margin: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.banner-overlay {
    background-image:url('/images/app/header-img.png');
    height: 700px;
    width: 1260px;
    margin:0 auto;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
}
.banner-caption {
    position: absolute;
    top: 40%;
    left: 13%;
    width: 600px;
    h1 {
        font-size: 32px;
        font-weight: 400;
        color:white;
        letter-spacing: 5px;
    }
    .projectBtn {
        position: absolute;
        bottom:-100px;
    }
}
.projectBtn {
    border: 1px solid white;
    padding:25px 50px;
    font-size:10px;
    font-weight: 300;
    letter-spacing: 4px;
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    color: rgb(101,203,248);
}
.home-section-container {
    background: #e5e5e5;
    width: 1260px;
    margin: -38px auto 0;
    position: relative;
}
#page-services,
#page-projects,
#page-contact,
#page-\{all\},
#page-inner,
#page-login {
    .home-section-container {
        margin:0 auto;
    }
    .home-banner-container {
	   background: linear-gradient(#074d6b 55%, #0a5577 55%);
    }
    #welcome {
        border:none;
        padding:10px 50px;
        .welcome-bottom-icon {
            display:none;
            visibility: none;
        }
    }
    .banner-overlay {
        height:350px;
    }
    .banner-caption {
	   display:none;
    }
    #service-list {
        background-color:white;
        padding-top:20px;
        padding-bottom:40px;
        position:relative;
    }
    #case-studies {
        padding:10px;
        margin-top:40px;
        margin-bottom:-3px;
    }
}
#page-services  {
    #service-list {
        background-color:white;
        padding-top:20px;
        padding-bottom:40px;
        position:relative;
    }
    #case-studies {
        padding:10px 10px 50px;
        margin-bottom:-65px;
    }
}
#page-projects  {
    #welcome {
        top:-30px;
    }
    #case-studies {
        padding:25px 10px 40px;
        margin-bottom:-33px;
        top:-30px;
        margin-top:0;
    }
    .case-studies-grid {
        padding:12px;
        border:none;
    }
}
.accreditation {
    display: inline-block;
    width:20%;
    margin:0 1em;
    img {
        width:100%;
    }
}
#page-contact {
    #welcome {
        top:-30px;
    }
    .contact-form {
        width:75%;
        margin:0 auto;
    }
    input[type="submit"] {
        border: 1px solid white;
        padding:20px 50px;
        font-size:12px;
        letter-spacing: 3px;
        font-family: 'Open Sans', sans-serif;
        text-transform: uppercase;
        background-color: rgb(7,76,107);
        color: rgb(101,203,248);
        margin:20px 0;
    }
    #case-studies {
        padding:25px 10px 40px;
        margin-bottom:-30px;
        top:-30px;
    }
    .case-studies-grid {
        padding:12px;
        border:none;
    }
    #contact-map {
        width:100%;
        height:400px;
        margin:1em auto;
    }
}
#page-login {
    input[type="password"], input[type="email"] {
        font-size:16pz;
        height:30px;
        padding:0 0 0 15px;
        width:50%;
        margin:0 auto;
    }
    #welcome {
        padding:40px 100px;
    }
    .form-group {
        padding:10px;
    }
    #welcome {
        padding:50px 100px;
        top:-30px;
    }
}

#page-inner {
    #welcome {
        padding:10px 40px;
        background:linear-gradient(rgb(255,255,255) 30%, rgb(238,238,238) 30%);
        h2 {
            text-align: left;
            background-color: rgb(200,200,200);
            color: rgb(0,70,100);
            font-size: 21px;
            padding: 20px;
            border-bottom: 2px solid white;
            width: 70%;
            font-weight:300;
            margin:20px 0 0;
            &:after {
                content: "";
                position: absolute;
                display: block;
                width: 175px;
                height: 35px;
                top: 30px;
                right: 220px;
                z-index:-1;
                background-color:rgb(200,200,200);
                border-bottom: 32px solid rgb(200,200,200);
                border-right: 0px solid rgb(200,200,200);
                transform-origin: bottom right;
                -ms-transform: skew(40deg, 0deg);
                -webkit-transform: skew(40deg, 0deg);
                transform: skew(40deg, 0deg);
            }
        }
        .welcome-content {
            column-count: 2;
            column-gap:40px;
            p {
                width:100%;
                display: block;
                text-align: left;
            }
        }
        .project-content {
            position: relative;
            text-align: left;
            margin:0 0 4rem;
            h2 {
                background-color:rgb(207,207,207);
                color:rgb(9,85,115);
                font-size:18px;
                width:80%;
                padding:15px;
                margin:20px 0 0;
                &:after {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 80%;
                    height: 50px;
                    top: 0;
                    left: 0;
                    z-index:-1;
                    background-color:rgb(207,207,207);
                    border-bottom: 3px solid rgb(207,207,207);
                    border-right: 20px solid rgb(207,207,207);
                    transform-origin: top left;
                    -ms-transform: skew(30deg, 0deg);
                    -webkit-transform: skew(30deg, 0deg);
                    transform: skew(30deg, 0deg);
                }
            }
                    .project-desc {
                    background-color:rgb(210,89,37);
	                color:white;
                    font-size:13px;
                    letter-spacing: 1px;
                    z-index:2;
                    position: absolute;
                    top:0;
                    margin:0;
                    padding:20px 0 20px 35px;
                    font-weight: 200;
                    height:auto;
                    width:42%;
                    p {
                        line-height:22px;
                        list-style:disc outside none;
                        display:list-item; 
                        border:none;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        display: block;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left:0;
                        z-index:-1;
                        background-color:rgb(210,89,37);
                        transform-origin: bottom right;
                        -ms-transform: skewX(-30deg);
                        -webkit-transform: skewX(-30deg);
                        transform: skewX(-30deg);
                    }
                }
            /* GALLERY HERE */
            .project-gallery {
                position: relative;
                display:flex;
                flex-flow:row wrap;
                .list1 {
                    width:50%;
                    img {
                        height:100%;
                    }
                }
                .list2 {
                    width:25%;
                    border-left:2px solid white;
                    border-right:2px solid white;
                    img:first-of-type {
                        border-bottom:2px solid white;
                    }
                    img {
                        height:50%;
                    }
                }
                .list3 {
                    width:25%;
                    overflow-x: hidden;
                    img {
                        height:100%;
                        width:auto;
                        position: relative;
                        left: 50%;
                        transform:translateX(-50%);
                        
                    }
                }
                li {
                    max-height:420px;
                    img {
                        z-index:1;
                        width: 100%;
                    }
                }
            }
            .project-tag {
                background-color:rgb(7,77,107);
                border-top:2px solid white;
                border-bottom:8px solid rgb(4,57,81);
                color:white;
                font-weight: 200;
                position: relative;
                h1 {
                    display: inline-block;
                    width:70%;
                    font-size:21px;
                    margin:4% 0;
                    padding:0 25px;
                    font-weight: 200;
                }
                a {
                    display:inline-block;
                    position: absolute;
                    right: 25px;
                    top:50%;
                    transform:translateY(-50%);
                }
            }
        }
    }
    #case-studies {
        padding:25px 10px 40px;
        margin-bottom:-3px;
    }
    #footer {
        position:relative;
        margin-top:-65px;
    }
}
.arrow {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 20px;
}
.arrow:before,
.arrow:after {
    content: '';
    position: absolute;
    top: -40px;
    width: 50%;
    box-sizing: border-box;
}
.arrow:before {
    right: 50%;
    border-bottom: 40px solid rgb(255,255,255);
    border-right: 40px solid transparent;
}
.arrow:after {
    left: 50%;
    border-bottom: 40px solid rgb(255,255,255);
    border-left: 40px solid transparent;
}
#welcome {
    background: #ffffff;
    border-top: 4px solid rgb(210,89,38);
    text-align: center;
    padding: 40px 100px 0 100px;
    position: relative;
    top: -65px;
    z-index: 1;
    h2 {
        color: rgb(10,86,120);
        font-weight: 300;
        font-size: 43px;
        margin: 20px 0 40px;
    }
}
.welcome-content {
    padding:0 0 20px;
    font-size:20px;
    p:first-child {
        font-size: 20px;
    }
    p {
        margin: 0 0 30px 0;
        font-size:16px;
    }
}
.welcome-bottom-icon {
    margin: 0 0 -53px;
    padding:0 0 5px;
    user-select:none;
    pointer-events: none;
}
#case-studies {
    text-align: center;
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 70px 0 0;
    background: #eeeeee;
    position: relative;
    top:-65px;
    h2 {
        color: rgb(10,86,120);
        text-transform: capitalize;
        margin: 0 0 30px 0;
        font-weight: 400;
        font-size:36px;
    }
}
.case-studies-grid {
    text-align: center;
    float: left;
    height:auto;
    width: 33.33333%;
    border-left: 12px solid white;
    border-right: 12px solid white;
}
.case-studies-item {
    background: rgb(7,76,107);
    border-bottom: 8px solid rgb(5,58,82);
    h3 {
        font-weight: 400;
        margin: 30px 0;
        text-transform: uppercase;
    }
    img {
        width: 100%;
    }
    .projectBtn {
        display:block;
        margin:30px auto;
        width:75%;
        padding:20px 25px;
    }
}


/* ===============================================
 * PAGE
 * ============================================= */
.default-page {
}
.image-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    width:80%;
    margin: 0 auto;
    li {
        width:30%;
        margin:10px;
        height:200px;
        border:1px solid rgb(229,229,229);
        img {
            max-width:75%;
            max-height:90%;
            position: relative;
            display: block;
            margin:auto;
            top:50%;
            transform: translateY(-50%);
        }
    }
}
.page-banner {
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    position: relative;
    text-align: center;
    h1 {
        position: relative;
        top: 320px;
        left: 0;
        display: inline-block;
        margin: auto;
        padding: 26px;
        text-align: center;
        border: 2px solid #debb4c;
        font-size: 30px;
        font-weight: 400;
        text-transform: uppercase;
        color: #ffffff;
        letter-spacing: 5px;
        background: rgba(222, 187, 76, 0.8);
    }
}
.page-content {
    padding: 60px 0;
    p {
        margin: 0 0 30px;
    }
}
#page-services {
    .welcome-content {
        p {
            margin:0;
            font-size:16px;
        }
    }
    .service:nth-of-type(3n+2){ //Every 2nd item, starting at the 2nd.
        background-color:rgb(20,130,182);
        .service-img {
            order: 2;
        }
        .service-text {
           order:1;
        }
    }
    .service:nth-of-type(3n){ //Every 3rd item, starting at the 1st.
        background-color:rgb(14,108,149);
        .service-img {
            order: 2;
        }
        .service-text {
           order:1;
        }
    }
    .service { //Every 1st & base styling
        display:flex;
        background-color:rgb(10,86,120);
        color:white;
        margin:2px auto;
        width:100%;
        .service-img {
            width:50%;
            overflow: hidden;
            img {
                position: relative;
                width:100%;
                height:100%;
            }
        }
        .service-text {
            display:inline-block;
            padding:30px 40px;
            width:50%;
            font-size:0.95em;
            text-align: left;
            h2 {
                padding:0;
                font-size:2rem;
                letter-spacing:1px;
                color:white;
            }
            ul {
                line-height:22px;
                padding:0 0 0 20px;
                list-style:disc outside none;
                p {
                    display:list-item; 
                }
            }
        }
    }
}

/* ===============================================
 * BLOG
 * ============================================= */
.blog-item {
	width: 33.33333%;
	overflow: hidden;
	padding: 10px;
    h3 {
	   font-weight: 600;
        font-size: 18px;
        color: #9a1419;
        padding: 10px;
    }
}
.blog-sizer {
	width: 33.33333%;
}
.blog-item:hover .overlay {
	display: block;
}
.blog-item:hover .readmore {
	top: 50%;
}
.blog-item-image {
    background: #ccc;
    min-height: 200px;
	position: relative;
	overflow: hidden;
    img {
        width: 100%;
    }
}
.readmore {
    position: absolute;
    top: 150%;
    z-index: 2;
    left: 50%;
    background: #debb4c;
    color: #fff;
    display: block;
    width: 150px;
    text-align: center;
    padding: 10px 0;
    margin: -20px 0 0 -75px;
	transition: 0.8s;
}
.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
	display: none;
}
.single-meta {
	display: inline-block;
	width: 100%;
	margin: 0 0 20px 0;
}
.single-meta > ul > li {
    float: left;
    margin: 0 20px 0 0;
	color: #690b0f;
}
.single-meta > ul > li i {
	margin: 0 10px 0 0;
}
/* ===============================================
 * CASE STUDIES
 * ============================================= */
.case-studies-entry {
    width: 33.33333%;
    float: left;
	padding: 0 15px;
    h3 {
        color: #690b0f;
        font-weight: 600;
        margin: 0 0 20px;
    }
}

.fulldetails {
    display: block;
    margin: 20px 0 0;
}
.case-studies {
    display: inline-block;
    width: 100%;
}
/* ===============================================
 * CONTACT
 * ============================================= */
.single-image {
	width: 400px;
	overflow: hidden;
	float: left;
	margin: 0 30px 15px 0;
    img {
        width: 100%;
    }
}
#page-login {
    .page-content {
        width:50%;
        margin:0 auto;
    }
    .page-banner {
        height:0;
    }
}
#page-contact {
    
}

/* ===============================================
 * CONTACT
 * ============================================= */

.contact-area {
    a {
        color:rgb(10,86,132);
    }
}

/* ===============================================
 * MOBILE
 * ============================================= */
#mobile-menu {
    display: none;
    
}
.mobile-btn {
    color: #690b0f;
    cursor: pointer;
    float: right;
    top: 17px;
    position: relative;
}
#mobile-navigation {
    position: fixed;
    width: 50%;
    height: 100%;
    top: 0;
    left: -100%;
    background: #2f2f2f;
    overflow-y: scroll;
    box-shadow: 0 0 20px #000;
    transition: 0.5s;
}
#mobile-navigation > ul > li > a {
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 300;
    display: block;
    padding: 10px 20px;
    border-bottom: 1px solid #000;
}
/* ===============================================
 * FOOTER
 * ============================================= */
#footer {
    background: linear-gradient(rgb(5,58,82) 15%,rgb(7,76,107) 15%, rgb(7,76,107) 30%,rgb(10,86,119) 30%);
    padding: 135px 0 0;
    display: inline-block;
    width: 100%;
    a {
        color:#fff;
    }
}
#email {
    text-align: center;
    padding:20px 0;
    text-align: center;
    font-size: 18px;
    letter-spacing: 1px;
    margin: auto;
    span {
        position: relative;
        top: 25px;
    }
    a {
        background-color:rgb(5,58,82);
        color:rgb(59,159,204); 
        padding:20px 40px;
    }
}
.tel {
    text-align: center;
    padding:20px 0;
    font-size:30px;
    letter-spacing: 1px;
    margin:auto;
}
.footer-container-menu {
    background: rgb(10,86,119);
    display: inline-block;
    width: 100%;
    padding: 50px 0 70px;
}
.footer-menus {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 40px 0;
}
#footer-navigation {

}
#footer-navigation > ul > li {
    display: inline;
    margin: 0 30px 0 0;
}
#footer-navigation > ul > li > a {
    color: #fff;
}
.copyrights {
    color: #fff;
    text-align: center;
}
#footer .designed {
    color:rgb(59,159,204);
    a {
      color:rgb(59,159,204);  
    }
}